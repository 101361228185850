import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  Nullable,
  Nullish,
  ResourceType,
} from '@tager/web-core';
import { MapEntry } from '@tager/web-core/src/typings/common';

import { CollectionType, NullablePpoductType } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import {
  getCollectionByAlias,
  getCollectionProductsById,
} from '@/services/requests';

const collectionsLoader = createResourceLoader<CollectionType>(null);
const collectionProductsLoader = createResourceLoader<NullablePpoductType[]>(
  []
);

type CollectionState = {
  collections: Record<
    string,
    { products?: ResourceType<NullablePpoductType[]> } & ResourceType<
      Nullable<CollectionType>
    >
  >;
};

const initialState: CollectionState = {
  collections: {},
};

const collectionSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    /**Collection**/
    collectionRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.collections[action.payload.key] = collectionsLoader.pending();
    },
    collectionRequestFullfiled(
      state,
      action: PayloadAction<MapEntry<string, CollectionType>>
    ) {
      state.collections[action.payload.key] = collectionsLoader.fulfill(
        action.payload.value
      );
    },
    collectionRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.collections[action.payload.key] = collectionsLoader.reject();
    },
    /**Products**/
    collectionProductsRequestPending(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.collections[action.payload.key].products =
        collectionProductsLoader.pending();
    },
    collectionProductsRequestFullfiled(
      state,
      action: PayloadAction<MapEntry<string, NullablePpoductType[]>>
    ) {
      state.collections[action.payload.key].products =
        collectionProductsLoader.fulfill(action.payload.value);
    },
    collectionProductsRequestRejected(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.collections[action.payload.key].products =
        collectionProductsLoader.reject();
    },
  },
});

const { actions, reducer } = collectionSlice;

export const {
  collectionProductsRequestFullfiled,
  collectionProductsRequestPending,
  collectionProductsRequestRejected,
  collectionRequestFullfiled,
  collectionRequestPending,
  collectionRequestRejected,
} = actions;

export default reducer;

export const getCollectionByAliasThunk =
  (
    alias: string,
    options?: {
      shouldInvalidate?: boolean;
    }
  ): AppThunk<Promise<CollectionType>> =>
  async (dispatch, getState): Promise<Nullable<CollectionType>> => {
    try {
      dispatch(collectionRequestPending({ key: alias }));
      const reponse = await getCollectionByAlias(alias);
      dispatch(collectionRequestFullfiled({ key: alias, value: reponse.data }));
      return reponse.data;
    } catch (e) {
      dispatch(collectionRequestRejected({ key: alias }));
      return null;
    }
  };

export const getCollectionProductsByIdThunk =
  (
    alias: string,
    id: number,
    searchParams: URLSearchParams,
    options?: {
      shouldInvalidate?: boolean;
    }
  ): AppThunk<Promise<NullablePpoductType[]>> =>
  async (dispatch, getState): Promise<NullablePpoductType[]> => {
    try {
      dispatch(collectionProductsRequestPending({ key: alias }));
      const response = await getCollectionProductsById(id, searchParams);
      dispatch(
        collectionProductsRequestFullfiled({ key: alias, value: response.data })
      );
      return response.data;
    } catch (error) {
      dispatch(collectionProductsRequestRejected({ key: alias }));
      return [];
    }
  };

export const selectCollectionResourceByAlias = (
  state: AppState,
  alias: string
): ResourceType<CollectionType> => state.pages.collections.collections[alias];

export const selectCollectionProductsResourceById = (
  state: AppState,
  alias: string
): Nullish<ResourceType<NullablePpoductType[]>> =>
  state.pages.collections.collections[alias].products;
