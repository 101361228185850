import { Option } from '@tager/web-core';

export enum SORT_TYPE {
  Popular = 'POPULAR',
  New = 'NEW',
  PriceHigh = 'PRICE_HIGH',
  PriceLow = 'PRICE_LOW',
}

export const OPTIONS: Option[] = [
  {
    label: 'Популярные',
    value: SORT_TYPE.Popular,
  },
  {
    label: 'Новые',
    value: SORT_TYPE.New,
  },
  {
    label: 'Дорогие',
    value: SORT_TYPE.PriceHigh,
  },
  {
    label: 'Дешевые',
    value: SORT_TYPE.PriceLow,
  },
];
