import * as React from 'react';

export interface ModalsOpenStateType {
  openModalState: () => void;
  closeModalState: () => void;
  isModalVisible: boolean;
}

export const ModalsOpenStateContext =
  React.createContext<ModalsOpenStateType | null>(null);

interface Props {
  children: React.ReactNode;
}

function ModalsOpenStateProvider({ children }: Props) {
  const [isModalVisible, setModalVisible] = React.useState(false);

  const openModalState = () => {
    setModalVisible(true);
  };
  const closeModalState = () => {
    setModalVisible(false);
  };

  const values = {
    openModalState,
    closeModalState,
    isModalVisible,
  } as ModalsOpenStateType;

  return (
    <ModalsOpenStateContext.Provider value={values}>
      {children}
    </ModalsOpenStateContext.Provider>
  );
}

export default ModalsOpenStateProvider;
