import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, MapEntry, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { ProductPreview } from '@/typings/model';
import { getProductListByGroupList } from '@/services/requests';

const productsLoader = createResourceLoader<Array<ProductPreview>>([]);

type ProductsState = {
  products: Record<string, ResourceType<Array<ProductPreview>>>;
};

const initialState: ProductsState = {
  products: {},
};

const productsSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {
    /** Products */
    productListRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.products[action.payload.key] = productsLoader.pending();
    },
    productListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, Array<ProductPreview>>>
    ) {
      state.products[action.payload.key] = productsLoader.fulfill(
        action.payload.value
      );
    },
    productListRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.products[action.payload.key] = productsLoader.reject();
    },
  },
});

const { actions, reducer } = productsSlice;
export const {
  productListRequestPending,
  productListRequestFulfilled,
  productListRequestRejected,
} = actions;

export function getProductListByGroupThunk(
  group: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductPreview>>> {
  return async (dispatch, getState) => {
    try {
      const product = selectProductListByGroupResource(getState(), group);

      if (!options?.shouldInvalidate && product) {
        return product.data;
      }
      dispatch(productListRequestPending({ key: group }));
      const response = await getProductListByGroupList(group);
      dispatch(
        productListRequestFulfilled({
          key: group,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(productListRequestRejected({ key: group }));
      return [];
    }
  };
}

export function selectProductListByGroupResource(
  state: AppState,
  group: string
): ResourceType<Array<ProductPreview>> {
  return state.pages.products.products[group];
}

export default reducer;
