import { FunctionComponent } from 'react';

import { Nullable } from '@tager/web-core';

import { CustomApp_PageContext } from '@/typings/hocs';

export type PageModuleType = {
  component: FunctionComponent;
  getInitialProps?: (context: CustomApp_PageContext) => Promise<unknown>;
  template: string;
};

export type CatalogCardVariant = 'square' | 'landscape' | 'portrait';
export type StockVariant = 'SHOP' | 'WAITING' | 'STOCK' | 'IN_SHOP' | 'NO';
export type PostCardVariant = 'big' | 'small' | 'search';

export type StringFieldType = Nullable<string>;

export type BreadCrumbType = {
  label?: string;
  url?: string;
};

export interface BreadCrumbInt {
  breadCrumbs: BreadCrumbType[];
}

export type PaymentIconType =
  | 'wallet'
  | 'card'
  | 'invoice'
  | 'credit'
  | 'cards'
  | 'monitor';

export type CatalogCategoryVariant = 'PRODUCT' | 'CATALOG';

export enum Status {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}
