import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createPaginatedResourceLoader,
  createResourceLoader,
  Nullable,
  ResourceType,
} from '@tager/web-core';
import {
  MapEntry,
  PaginatedResourceType,
  PaginationMeta,
  QueryParams,
} from '@tager/web-core/src/typings/common';

import {
  CatalogCategoryType,
  CatalogExtendedRelation,
  CategoryRelation,
  ProductType,
} from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import {
  getCatalogCategoryByAlias,
  getCatalogCategoryProductsById,
  getCatalogCategoryRelationsById,
  getCatalogCategoryRelationsExtendedById,
} from '@/services/requests';
import { filterPassedQueryParams } from '@/utils/common';

const categoriesLoader = createResourceLoader<CatalogCategoryType>(null);
const categoriesRelationsLoader = createResourceLoader<CategoryRelation[]>([]);
const categoriesRelationsExtendedLoader = createResourceLoader<
  CatalogExtendedRelation[]
>([]);

const categoriesProductsLoader = createPaginatedResourceLoader<ProductType>([]);

export type Category = {
  relations?: ResourceType<Nullable<CategoryRelation[]>>;
  relationsExtended?: ResourceType<Nullable<CatalogExtendedRelation[]>>;
  products?: PaginatedResourceType<ProductType>;
} & ResourceType<Nullable<CatalogCategoryType>>;

type Categories = {
  categories: Record<string, Category>;
  currentPage: number;
};

const initialState: Categories = {
  categories: {},
  currentPage: 1,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    /**Category**/
    categoryRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.categories[action.payload.key] = categoriesLoader.pending();
    },
    categoryRequestFullfiled(
      state,
      action: PayloadAction<MapEntry<string, CatalogCategoryType>>
    ) {
      state.categories[action.payload.key] = categoriesLoader.fulfill(
        action.payload.value
      );
    },
    categoryRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.categories[action.payload.key] = categoriesLoader.reject();
    },
    /**Relations**/
    categoryRelationsRequestPending(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.categories[action.payload.key].relations =
        categoriesRelationsLoader.pending();
    },
    categoryRelationsRequestFullfiled(
      state,
      action: PayloadAction<MapEntry<string, CategoryRelation[]>>
    ) {
      state.categories[action.payload.key].relations =
        categoriesRelationsLoader.fulfill(action.payload.value);
    },
    categoryRelationsRequestRejected(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.categories[action.payload.key].relations =
        categoriesRelationsLoader.reject();
    },
    /**RelationsExtended**/
    categoryRelationsExtendedRequestPending(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.categories[action.payload.key].relationsExtended =
        categoriesRelationsExtendedLoader.pending();
    },
    categoryRelationsExtendedRequestFullfiled(
      state,
      action: PayloadAction<MapEntry<string, CatalogExtendedRelation[]>>
    ) {
      state.categories[action.payload.key].relationsExtended =
        categoriesRelationsExtendedLoader.fulfill(action.payload.value);
    },
    categoryRelationsExtendedRequestRejected(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.categories[action.payload.key].relationsExtended =
        categoriesRelationsExtendedLoader.reject();
    },
    /**Products**/
    categoryProductsRequestPending(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.categories[action.payload.key].products =
        categoriesProductsLoader.pending();
    },
    categoryProductsRequestFullfiled(
      state,
      action: PayloadAction<{
        key: string;
        value: ProductType[];
        meta: PaginationMeta;
      }>
    ) {
      state.categories[action.payload.key].products =
        categoriesProductsLoader.fulfill(
          action.payload.value,
          action.payload.meta
        );
    },
    categoryProductsRequestRejected(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.categories[action.payload.key].products =
        categoriesProductsLoader.reject();
    },
    /**setCurrentPage**/
    currentPageAdded(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
});

const { reducer, actions } = categorySlice;
export default reducer;
export const {
  categoryProductsRequestFullfiled,
  categoryProductsRequestPending,
  categoryProductsRequestRejected,
  categoryRelationsExtendedRequestFullfiled,
  categoryRelationsExtendedRequestPending,
  categoryRelationsExtendedRequestRejected,
  categoryRelationsRequestFullfiled,
  categoryRelationsRequestPending,
  categoryRelationsRequestRejected,
  categoryRequestFullfiled,
  categoryRequestPending,
  categoryRequestRejected,
  currentPageAdded,
} = actions;

export const getCategoryByAliasThunk =
  (
    alias: string,
    options?: {
      shouldInvalidate?: boolean;
    }
  ): AppThunk<Promise<CatalogCategoryType>> =>
  async (dispatch, getState): Promise<Nullable<CatalogCategoryType>> => {
    try {
      dispatch(categoryRequestPending({ key: alias }));
      const response = await getCatalogCategoryByAlias({ path: alias });
      dispatch(categoryRequestFullfiled({ key: alias, value: response.data }));
      return response.data;
    } catch (error) {
      dispatch(categoryRequestRejected({ key: alias }));
      return null;
    }
  };

export const getCategoryRelationsByIdThunk =
  (
    alias: string,
    id: number,
    options?: {
      shouldInvalidate?: boolean;
    }
  ): AppThunk<Promise<CategoryRelation[]>> =>
  async (dispatch, getState): Promise<CategoryRelation[]> => {
    try {
      dispatch(categoryRelationsRequestPending({ key: alias }));
      const response = await getCatalogCategoryRelationsById(id);
      dispatch(
        categoryRelationsRequestFullfiled({ key: alias, value: response.data })
      );
      return response.data;
    } catch (error) {
      dispatch(categoryRelationsRequestRejected({ key: alias }));
      return [];
    }
  };

export const getcategoryRelationsExtendedByIdThunk =
  (
    alias: string,
    id: number,
    options?: {
      shouldInvalidate?: boolean;
    }
  ): AppThunk<Promise<CatalogExtendedRelation[]>> =>
  async (dispatch, getState): Promise<CatalogExtendedRelation[]> => {
    try {
      dispatch(categoryRelationsExtendedRequestPending({ key: alias }));
      const response = await getCatalogCategoryRelationsExtendedById(id);
      dispatch(
        categoryRelationsExtendedRequestFullfiled({
          key: alias,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(categoryRelationsExtendedRequestRejected({ key: alias }));
      return [];
    }
  };

export const getCategoryProductsByIdThunk =
  (
    alias: string,
    id: number,
    params: QueryParams = {},
    options?: {
      shouldInvalidate?: boolean;
    }
  ): AppThunk<Promise<ProductType[]>> =>
  async (dispatch, getState): Promise<ProductType[]> => {
    const filteredParams = filterPassedQueryParams(params);

    try {
      dispatch(categoryProductsRequestPending({ key: alias }));
      const response = await getCatalogCategoryProductsById(id, filteredParams);
      dispatch(
        categoryProductsRequestFullfiled({
          key: alias,
          value: response.data,
          meta: response.meta,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(categoryProductsRequestRejected({ key: alias }));
      return [];
    }
  };

export const selectCategoryByAlias = (
  state: AppState,
  alias: string
): ResourceType<CatalogCategoryType> => state.pages.catalog.categories[alias];

export const selectCategoryRelationsByAlias = (
  state: AppState,
  alias: string
): ResourceType<Nullable<CategoryRelation[]>> | undefined =>
  state.pages.catalog.categories[alias].relations;

export const selectCategoryRelationsExtendedByAlias = (
  state: AppState,
  alias: string
): ResourceType<Nullable<CatalogExtendedRelation[]>> | undefined =>
  state.pages.catalog.categories[alias].relationsExtended;

export const selectCategoryProductsByAlias = (
  state: AppState,
  alias: string
): PaginatedResourceType<ProductType> | undefined => {
  return state.pages.catalog.categories[alias].products;
};

export const selectCurrentPage = (state: AppState): number =>
  state.pages.catalog.currentPage;
