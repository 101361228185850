import { ModalProps } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { Values } from '@/store/reducers/pages/filters/CatalogProductFilters/filters';

export enum FilterTypes {
  Range = 'RANGE',
  MultiSelect = 'MULTISELECT',
  Radio = 'RADIO',
  TrueFalse = 'TRUE_FALSE',
}

export type FilterOption = {
  id: string;
  label: string;
  checked: boolean;
};

export type Filter = {
  id: string;
  label: Nullable<string>;
  description: Nullable<string>;
  type: FilterTypes;
  rangeFrom: Nullable<number>;
  rangeTo: Nullable<number>;
  valueFrom: Nullable<number>;
  valueTo: Nullable<number>;
  options: FilterOption[];
};

export type FilterProps = ModalProps<{
  productId: number;
  productAlias: string;
  productsCount?: number;
}>;

type Params =
  | { id: string; type: FilterTypes.MultiSelect; value: string[] }
  | { id: string; type: FilterTypes.Range; value: number[] }
  | {
      id: string;
      type: FilterTypes.TrueFalse;
      value: Nullable<boolean>;
    }
  | { id: string; type: FilterTypes.Radio; value: boolean | null };

export type HandleChange = (params: Params) => void;

export type FilterViewProps = {
  fields: Filter[];
  values: Values;
  closeModal: () => void;
  onChange: HandleChange;
  onSubmit: () => void;
  onReset: () => void;
};

export type FilteredValueField = string | number | boolean | null;

export type RangeFilterField = Omit<Filter, 'type'> & {
  type: FilterTypes.Range;
  value: number[];
};

export type TrueFalseFilterField = Omit<Filter, 'type'> & {
  type: FilterTypes.TrueFalse;
  value: Nullable<boolean>;
};

export type RadioFilterField = Omit<Filter, 'type'> & {
  type: FilterTypes.Radio;
  value: Nullable<boolean>;
};

export type MultiselectFilterField = Omit<Filter, 'type'> & {
  type: FilterTypes.MultiSelect;
  value: string[];
};

export type FilteredFields =
  | RangeFilterField
  | TrueFalseFilterField
  | RadioFilterField
  | MultiselectFilterField;

export type RangeParameters = Array<number>;
export type RadioParameters = { label: string; value: boolean };
export type TrueFalseParameters = { label: string; value: Nullable<boolean> };
export type MultiselectParameters = { label: string; value: boolean };

export type OnChangeParamsMap = {
  [FilterTypes.Range]: RangeParameters;
  [FilterTypes.Radio]: RadioParameters;
  [FilterTypes.TrueFalse]: TrueFalseParameters;
  [FilterTypes.MultiSelect]: MultiselectParameters;
};
