import { FullProductType, ProductSize } from '@/typings/model';

import { ProductVM } from './types';

export const convertProductToSize = (
  product: FullProductType
): ProductSize[] => {
  const result: ProductSize[] = [];

  const width = product.params.find(
    (param) => param.field.type === 'NUMBER' && param.field.name === 'DLINA'
  )?.value as number;
  const height = product.params.find(
    (param) => param.field.type === 'NUMBER' && param.field.name === 'VYSOTA'
  )?.value as number;
  const depth = product.params.find(
    (param) => param.field.type === 'NUMBER' && param.field.name === 'GLUBINA'
  )?.value as number;

  if (width) {
    result.push({
      value: width,
      label: 'Длина',
      type: 'DLINA',
    });
  }
  if (height) {
    result.push({
      value: height,
      label: 'Высота',
      type: 'VYSOTA',
    });
  }
  if (depth) {
    result.push({
      value: depth,
      label: 'Глубина',
      type: 'GLUBINA',
    });
  }

  return result;
};

export const convertProductToMatressSize = (
  product: FullProductType
): ProductSize[] => {
  const result: ProductSize[] = [];

  const length = product.params.find(
    (param) =>
      param.field.type === 'NUMBER' &&
      param.field.name === 'DLINA-SPALNOGO-MESTA'
  )?.value as number;

  const width = product.params.find(
    (param) =>
      param.field.type === 'NUMBER' &&
      param.field.name === 'SHIRINA-SPALNOGO-MESTA'
  )?.value as number;

  if (length) {
    result.push({
      value: length,
      label: 'Длина спального места',
      type: 'SHIRINA-SPALNOGO-MESTA',
    });
  }

  if (width) {
    result.push({
      value: width,
      label: 'Ширина спального места',
      type: 'DLINA-SPALNOGO-MESTA',
    });
  }

  return result;
};

export const forDetails = (product: FullProductType): ProductVM => {
  return {
    ...product,
    size: convertProductToSize(product),
    matressSize: convertProductToMatressSize(product),
  };
};
