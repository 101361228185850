import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  ResourceType,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { FullProductType, PostShort, ProductRelation } from '@/typings/model';
import {
  getProductByAlias,
  getProductPostListById,
  getProductRelationListById,
} from '@/services/requests';
import { forDetails } from '@/store/reducers/pages/product/viewModal';

const productLoader = createResourceLoader<Nullable<FullProductType>>(null);
const productRelationLoader = createResourceLoader<Array<ProductRelation>>([]);
const productPostLoader = createResourceLoader<Array<PostShort>>([]);

type ProductState = {
  productFullMap: Record<string, ResourceType<Nullable<FullProductType>>>;
  productRelationList: Record<number, ResourceType<Array<ProductRelation>>>;
  productPostList: Record<number, ResourceType<Array<PostShort>>>;
  productActiveId: number;
};

const initialState: ProductState = {
  productFullMap: {},
  productRelationList: [],
  productPostList: [],
  productActiveId: 0,
};

const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    /** Product full */
    productRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.productFullMap[action.payload.key] = productLoader.pending();
    },
    productRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, FullProductType>>
    ) {
      state.productFullMap[action.payload.key] = productLoader.fulfill(
        forDetails(action.payload.value)
      );
    },
    productRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.productFullMap[action.payload.key] = productLoader.reject();
    },

    /** Product Relation List */
    productRelationListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.productRelationList[action.payload.key] =
        productRelationLoader.pending();
    },
    productRelationListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductRelation>>>
    ) {
      state.productRelationList[action.payload.key] =
        productRelationLoader.fulfill(action.payload.value);
    },
    productRelationListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.productRelationList[action.payload.key] =
        productRelationLoader.reject();
    },

    /** Product Term List */
    productPostListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.productPostList[action.payload.key] = productPostLoader.pending();
    },
    productPostListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<PostShort>>>
    ) {
      state.productPostList[action.payload.key] = productPostLoader.fulfill(
        action.payload.value
      );
    },
    productPostListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.productPostList[action.payload.key] = productPostLoader.reject();
    },
    /** Active Id */
    activeIdAdded(state, action: PayloadAction<number>) {
      state.productActiveId = action.payload;
    },
  },
});

const { actions, reducer } = productSlice;
export const {
  productRequestPending,
  productRequestFulfilled,
  productRequestRejected,
  productRelationListRequestPending,
  productRelationListRequestFulfilled,
  productRelationListRequestRejected,
  productPostListRequestPending,
  productPostListRequestFulfilled,
  productPostListRequestRejected,
  activeIdAdded,
} = actions;

export function getProductByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<FullProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectProductResource(getState(), alias);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(productRequestPending({ key: alias }));
      const response = await getProductByAlias(alias);

      dispatch(
        productRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(productRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getProductRelationListByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductRelation>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectProductRelationListResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(productRelationListRequestPending({ key: id }));
      const response = await getProductRelationListById(id);

      dispatch(
        productRelationListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(productRelationListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function getProductPostListByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectProductPostListResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(productPostListRequestPending({ key: id }));
      const response = await getProductPostListById(id);

      dispatch(
        productPostListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(productPostListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectActiveId(state: AppState): number {
  return state.pages.product.productActiveId;
}

export function selectProductResource(
  state: AppState,
  alias: string
): ResourceType<Nullable<FullProductType>> {
  return state.pages.product.productFullMap[alias];
}

export function selectProductRelationListResource(
  state: AppState,
  id: number
): ResourceType<Array<ProductRelation>> {
  return state.pages.product.productRelationList[id];
}

export function selectProductPostListResource(
  state: AppState,
  id: number
): ResourceType<Array<PostShort>> {
  return state.pages.product.productPostList[id];
}

export function selectProduct(
  state: AppState,
  alias: string
): Nullable<FullProductType> {
  return selectProductResource(state, alias).data;
}

export default reducer;
