import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  CategoryFull,
  CategoryShort,
  PostFull,
  PostShort,
  SeoBlogPageType,
  GlossaryCardType,
} from '@/typings/model';
import {
  getBlogCategoryList,
  getCategoryByAlias,
  getPostByAlias,
  getPostList,
  getSeoParams,
  getGlossary,
} from '@/services/requests';

const seoParamsLoader = createResourceLoader<SeoBlogPageType>(null);
const postListLoader = createResourceLoader<Array<PostShort>>([]);
const postLoader = createResourceLoader<Nullable<PostFull>>(null);
const categoryListLoader = createResourceLoader<Array<CategoryShort>>([]);
const categoryLoader = createResourceLoader<Nullable<CategoryFull>>(null);
const glossaryLoader = createResourceLoader<Array<GlossaryCardType>>([]);

type State = {
  seoParams: ResourceType<SeoBlogPageType>;
  postShortList: ResourceType<Array<PostShort>>;
  postFullMap: Record<string, ResourceType<Nullable<PostFull>>>;
  categoryShortList: ResourceType<Array<CategoryShort>>;
  categoryFullMap: Record<string, ResourceType<Nullable<CategoryFull>>>;
  glossaryShortList: ResourceType<Array<GlossaryCardType>>;
};

const initialState: State = {
  seoParams: seoParamsLoader.getInitialResource(),
  postShortList: postListLoader.getInitialResource(),
  postFullMap: {},
  categoryShortList: categoryListLoader.getInitialResource(),
  categoryFullMap: {},
  glossaryShortList: glossaryLoader.getInitialResource(),
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    /** Term list */
    postListRequestPending(state) {
      state.postShortList = postListLoader.pending();
    },
    postListRequestFulfilled(state, action: PayloadAction<Array<PostShort>>) {
      state.postShortList = postListLoader.fulfill(action.payload);
    },
    postListRequestRejected(state) {
      state.postShortList = postListLoader.reject();
    },

    /** Term full */
    postRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.postFullMap[action.payload.key] = postLoader.pending();
    },
    postRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, PostFull>>
    ) {
      state.postFullMap[action.payload.key] = postLoader.fulfill(
        action.payload.value
      );
    },

    postRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.postFullMap[action.payload.key] = postLoader.reject();
    },

    /** Category list */
    categoryListRequestPending(state) {
      state.categoryShortList = categoryListLoader.pending();
    },
    categoryListRequestFulfilled(
      state,
      action: PayloadAction<Array<CategoryShort>>
    ) {
      state.categoryShortList = categoryListLoader.fulfill(action.payload);
    },
    categoryListRequestRejected(state) {
      state.categoryShortList = categoryListLoader.reject();
    },

    /** Category full */
    categoryRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.categoryFullMap[action.payload.key] = categoryLoader.pending();
    },
    categoryRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, CategoryFull>>
    ) {
      state.categoryFullMap[action.payload.key] = categoryLoader.fulfill(
        action.payload.value
      );
    },

    categoryRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.categoryFullMap[action.payload.key] = categoryLoader.reject();
    },

    /** Seo List */
    seoParamsRequestPending(state) {
      state.seoParams = seoParamsLoader.pending();
    },
    seoParamsRequestFulfilled(state, action: PayloadAction<SeoBlogPageType>) {
      state.seoParams = seoParamsLoader.fulfill(action.payload);
    },
    seoParamsRequestRejected(state) {
      state.seoParams = seoParamsLoader.reject();
    },

    /** Glossary */
    glossaryRequestPending(state) {
      state.glossaryShortList = glossaryLoader.pending();
    },
    glossaryRequestFulfilled(
      state,
      action: PayloadAction<Array<GlossaryCardType>>
    ) {
      state.glossaryShortList = glossaryLoader.fulfill(action.payload);
    },
    glossaryRequestRejected(state) {
      state.glossaryShortList = glossaryLoader.reject();
    },
  },
});

const { actions, reducer } = blogSlice;
export const {
  postListRequestPending,
  postListRequestFulfilled,
  postListRequestRejected,
  postRequestPending,
  postRequestFulfilled,
  postRequestRejected,
  categoryListRequestPending,
  categoryListRequestFulfilled,
  categoryListRequestRejected,
  categoryRequestPending,
  categoryRequestFulfilled,
  categoryRequestRejected,
  seoParamsRequestPending,
  seoParamsRequestFulfilled,
  seoParamsRequestRejected,
  glossaryRequestPending,
  glossaryRequestFulfilled,
  glossaryRequestRejected,
} = actions;

export default reducer;

export function getBlogPostListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    const postListResource = selectBlogPostListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      postListResource,
      options?.shouldInvalidate
    );
    if (shouldGetDataFromCache) {
      return postListResource.data;
    }
    dispatch(postListRequestPending());
    try {
      const response = await getPostList();
      dispatch(postListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(postListRequestRejected());
      return [];
    }
  };
}

export function getBlogCategoryListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<CategoryShort>>> {
  return async (dispatch, getState) => {
    const categoryListResource = selectBlogCategoryListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      categoryListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return categoryListResource.data;
    }

    dispatch(categoryListRequestPending());

    try {
      const response = await getBlogCategoryList();
      dispatch(categoryListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(categoryListRequestRejected());
      return [];
    }
  };
}

export function getBlogPostByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<PostFull>>> {
  return async (dispatch, getState) => {
    try {
      const post = selectBlogPostByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && post) {
        return post.data;
      }

      dispatch(postRequestPending({ key: alias }));
      const response = await getPostByAlias(alias);

      dispatch(
        postRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(postRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getBlogCategoryByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<CategoryFull>>> {
  return async (dispatch, getState) => {
    try {
      const category = selectBlogCategoryByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && category) {
        return category.data;
      }

      dispatch(categoryRequestPending({ key: alias }));
      const response = await getCategoryByAlias(alias);

      dispatch(
        categoryRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(categoryRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getSeoParamsThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Nullable<SeoBlogPageType>>> {
  return async (dispatch, getState) => {
    const seoParamsResource = selectSeoParamsResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      seoParamsResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return seoParamsResource.data;
    }

    dispatch(seoParamsRequestPending());

    try {
      const response = await getSeoParams();
      dispatch(seoParamsRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(seoParamsRequestRejected());
      return null;
    }
  };
}

export function getGlossaryThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<GlossaryCardType>>> {
  return async (dispatch, getState) => {
    const glossaryResource = selectGlossaryResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      glossaryResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return glossaryResource.data;
    }

    dispatch(glossaryRequestPending());

    try {
      const response = await getGlossary();
      dispatch(glossaryRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(glossaryRequestRejected());
      return [];
    }
  };
}

export function selectBlogPostListResource(
  state: AppState
): ResourceType<Array<PostShort>> {
  return state.tager.blog.postShortList;
}

export function selectBlogPostList(state: AppState): Array<PostShort> {
  return selectBlogPostListResource(state).data;
}

export function selectBlogCategoryListResource(
  state: AppState
): ResourceType<Array<CategoryShort>> {
  return state.tager.blog.categoryShortList;
}

export function selectBlogCategoryList(state: AppState): Array<CategoryShort> {
  return selectBlogCategoryListResource(state).data;
}

export function selectSeoParamsResource(
  state: AppState
): ResourceType<SeoBlogPageType> {
  return state.tager.blog.seoParams;
}

export function selectSeoParams(state: AppState): SeoBlogPageType {
  return selectSeoParamsResource(state).data;
}

export function selectGlossaryResource(
  state: AppState
): ResourceType<Array<GlossaryCardType>> {
  return state.tager.blog.glossaryShortList;
}

export function selectGlossary(state: AppState): Array<GlossaryCardType> {
  return selectGlossaryResource(state).data;
}

export function selectBlogPostByAliasResource(
  state: AppState,
  alias: string
): ResourceType<Nullable<PostFull>> {
  return state.tager.blog.postFullMap[alias];
}

export function selectBlogCategoryByAliasResource(
  state: AppState,
  alias: string
): ResourceType<Nullable<CategoryFull>> {
  return state.tager.blog.categoryFullMap[alias];
}
