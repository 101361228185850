import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FETCH_STATUSES, FetchStatus, Nullable } from '@tager/web-core';

import { getBrandRelatedProducts, getBrandsByAlias } from '@/services/requests';
import {
  BaseOfPageNullable,
  NullablePpoductType,
  ProductType,
} from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';

type Brands = Record<
  string,
  {
    status: FetchStatus;
    data: Nullable<BaseOfPageNullable>;
  }
>;

interface brandsState {
  alias: string;
  status: FetchStatus;
  data: Nullable<BaseOfPageNullable>;
  relatedProducts: NullablePpoductType[];
  productsStatus: FetchStatus;
}

const brandInitialState: brandsState = {
  alias: '',
  status: FETCH_STATUSES.IDLE,
  productsStatus: FETCH_STATUSES.IDLE,
  data: null,
  relatedProducts: [],
};

const brandSlice = createSlice({
  name: 'brand',
  initialState: brandInitialState,
  reducers: {
    brandsRequestPending(state, action: PayloadAction<string>) {
      state.status = FETCH_STATUSES.LOADING;
      state.alias = action.payload;
    },
    brandsRequestFulfilled(state, action: PayloadAction<BaseOfPageNullable>) {
      state.data = action.payload;
      state.status = FETCH_STATUSES.SUCCESS;
    },
    brandsRequestRejected(state) {
      state.status = FETCH_STATUSES.FAILURE;
    },
    brandProductsRequestPending(state) {
      state.productsStatus = FETCH_STATUSES.LOADING;
    },
    brandProductsRequestFulfilled(state, action: PayloadAction<ProductType[]>) {
      state.relatedProducts = action.payload;
      state.productsStatus = FETCH_STATUSES.SUCCESS;
    },
    brandProductsRequestRejected(state) {
      state.productsStatus = FETCH_STATUSES.FAILURE;
    },
  },
});

const { reducer, actions } = brandSlice;

export const {
  brandProductsRequestFulfilled,
  brandProductsRequestPending,
  brandProductsRequestRejected,
  brandsRequestFulfilled,
  brandsRequestPending,
  brandsRequestRejected,
} = actions;

export default reducer;

export function getBrandsByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<BaseOfPageNullable>> {
  return async (dispatch, getState): Promise<BaseOfPageNullable> => {
    try {
      dispatch(brandsRequestPending(alias));
      const response = await getBrandsByAlias(alias);
      dispatch(brandsRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(brandsRequestRejected());
      return null;
    }
  };
}

export function selectBrandByAlias(
  state: AppState,
  alias: string
): Nullable<BaseOfPageNullable> {
  return state.pages.brand.alias === alias ? state.pages.brand.data : null;
}

export function getBrandsRelatedProductsByIdThunk(
  id: number,
  searchParams: URLSearchParams,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<NullablePpoductType[]>> {
  return async (dispatch, getState): Promise<NullablePpoductType[]> => {
    try {
      const children = selectBrandRelatedProducts(getState());

      if (!options?.shouldInvalidate && children) {
        return children;
      }

      dispatch(brandProductsRequestPending());
      const response = await getBrandRelatedProducts(id, searchParams);
      dispatch(brandProductsRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(brandProductsRequestRejected());
      return [];
    }
  };
}

export function selectBrandRelatedProducts(
  state: AppState
): NullablePpoductType[] {
  return state.pages.brand.relatedProducts;
}

export function selectBrandstatus(state: AppState): string {
  return state.pages.brand.status;
}

export function selectBrandRelatedProductStatus(state: AppState): string {
  return state.pages.brand.productsStatus;
}
export const selectBrandAlias = (state: AppState): string =>
  state.pages.brand.alias;
