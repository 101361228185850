import React from 'react';
import { useRouter } from 'next/router';

import { isBrowser, RequestError } from '@tager/web-core';
import { QueryParams } from '@tager/web-core/src/typings/common';

import ErrorPage from '@/pages/_error';
import { StockVariant } from '@/typings/common';
import {
  DEFAULT_SEARCH_PARAMS_VALUES,
  SEARCH_PARAMS,
} from '@/constants/search';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function getApplicationName(): string | undefined {
  return process.env.NEXT_PUBLIC_APPLICATION_NAME;
}

export function getProductsOrPostsOrCategoriesWord(
  count: number,
  word: 'Products' | 'Posts' | 'Categories'
): string {
  if (count % 10 === 1 && count !== 11) {
    return word === 'Products'
      ? 'товар'
      : word === 'Posts'
      ? 'пост'
      : 'категория';
  }

  if (count % 10 >= 2 && count % 10 <= 4 && (count < 12 || count > 14)) {
    return word === 'Products'
      ? 'товара'
      : word === 'Posts'
      ? 'поста'
      : 'категории';
  }

  return word === 'Products'
    ? 'товаров'
    : word === 'Posts'
    ? 'постов'
    : 'категорий';
}

export function getStockLabel(stock: StockVariant | null) {
  switch (stock) {
    case 'WAITING':
      return 'Под заказ';
    case 'STOCK':
      return 'На складе';
    case 'IN_SHOP':
    case 'SHOP':
      return 'В магазине';
    default:
      return 'Нет в наличии';
  }
}

export function normalizePrice(price: number): string | number {
  if (!price) {
    return '';
  }
  let parsedPrice: string;
  if (Number.isInteger(price)) {
    parsedPrice = price.toString();
  } else {
    parsedPrice = price.toFixed(2);
  }
  return parsedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function getAliasString(
  alias: string | Array<string> | undefined
): string {
  if (!alias) {
    return '';
  }
  return Array.isArray(alias) ? alias[0] : alias ?? '';
}

export function convertAliasToPathQuery(alias: string): string {
  if (alias.indexOf('/') === 0) {
    return alias.slice(1);
  }
  return alias;
}

export function getCurrentPath(router: ReturnType<typeof useRouter>): string {
  return router.basePath && router.basePath !== '/'
    ? router.basePath + router.asPath
    : router.asPath;
}

export const getQueryParams = () => {
  if (!isBrowser()) {
    return;
  }
  const query = new URLSearchParams(window.location.search);
  if ([...query.entries()].length === 0) {
    return {};
  }
  return [...query.entries()].reduce<Record<string, string>>(
    (filter, [query, value]) => {
      filter[query] = value;
      return filter;
    },
    {}
  );
};

export const filterPassedQueryParams = (
  passedParams: QueryParams
): QueryParams => {
  return Object.keys(SEARCH_PARAMS).reduce<QueryParams>(
    (filteredParams, key) => {
      if (!filteredParams[key]) {
        filteredParams[key] = DEFAULT_SEARCH_PARAMS_VALUES[key];
      }
      return filteredParams;
    },
    { ...passedParams }
  );
};
