import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, AppThunk } from '@/store/store';
import { getProductFiltersByProductId } from '@/services/requests';
import { Status } from '@/typings/common';
import { Filter } from '@/typings/filter/types';
import { toValues } from '@/store/reducers/pages/filters/CatalogProductFilters/helpers';

export type Value = {
  type: string;
  data: null | boolean | Array<number> | Array<string>;
};
export type Values = Record<string, Value>;

type State = {
  data: Record<
    number,
    {
      status: Status;
      data: Filter[];
      values: Values;
    }
  >;
};

const initialState: State = {
  data: {},
};

const slice = createSlice({
  name: 'productFilters',
  initialState,
  reducers: {
    fetchProductFiltersLoading: (
      state,
      action: PayloadAction<{ productId: number }>
    ) => {
      state.data[action.payload.productId] = {
        status: Status.Loading,
        data: [],
        values: {},
      };
    },
    fetchProductFiltersSuccess: (
      state,
      action: PayloadAction<{ productId: number; filters: Filter[] }>
    ) => {
      state.data[action.payload.productId] = {
        status: Status.Success,
        data: action.payload.filters,
        values: toValues(action.payload.filters),
      };
    },
    fetchProductFiltersFailure: (
      state,
      action: PayloadAction<{ productId: number }>
    ) => {
      state.data[action.payload.productId] = {
        status: Status.Failure,
        data: state.data[action.payload.productId].data,
        values: state.data[action.payload.productId].values,
      };
    },
    updateFilterValueByProductIdAndFilterId: (
      state,
      action: PayloadAction<{
        productId: number;
        filterId: string;
        value: Value;
      }>
    ) => {
      state.data[action.payload.productId].values = {
        ...state.data[action.payload.productId].values,
        [action.payload.filterId]: action.payload.value,
      };
    },
    resetFiltersValues: (
      state,
      action: PayloadAction<{ productId: number }>
    ) => {
      state.data[action.payload.productId].values = toValues(
        state.data[action.payload.productId].data
      );
    },
  },
});

export default slice.reducer;

export const {
  fetchProductFiltersLoading,
  fetchProductFiltersSuccess,
  fetchProductFiltersFailure,
  updateFilterValueByProductIdAndFilterId,
  resetFiltersValues,
} = slice.actions;

export function fetchProductFiltersByProductIdThunk(
  productId: number
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(fetchProductFiltersLoading({ productId }));

    try {
      const { data: filters } = await getProductFiltersByProductId(productId);
      dispatch(fetchProductFiltersSuccess({ productId, filters }));
    } catch (error) {
      dispatch(fetchProductFiltersFailure({ productId }));
    }
  };
}

export const selectProductFiltersByProductId =
  (productId: number) =>
  (state: AppState): Filter[] => {
    return state.pages.productFilters.data[productId].data;
  };

export const selectFiltersValues =
  (productId: number) =>
  (state: AppState): Values => {
    return state.pages.productFilters.data[productId].values;
  };
