import { combineReducers } from 'redux';

import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';
import productsReducer from './pages/products';
import productReducer from './pages/product/product';
import blogReducer from './tager/blog';
import searchReducer from './pages/search';
import brandReducer from './pages/brands/brands';
import collectionsReducer from './pages/collection/collection';
import catalogReducer from './pages/catalog/catalog';
import catalogProductReducer from './pages/filters/CatalogProductFilters/filters';

const tagerReducer = combineReducers({
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
  blog: blogReducer,
});

const pageDataReducer = combineReducers({
  products: productsReducer,
  product: productReducer,
  search: searchReducer,
  brand: brandReducer,
  collections: collectionsReducer,
  catalog: catalogReducer,
  productFilters: catalogProductReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  pages: pageDataReducer,
});

export default rootReducer;
