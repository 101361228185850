import {ApiService, isServer, ResponseBody} from '@tager/web-core';
import {QueryParams} from '@tager/web-core/src/typings/common';

import {
  BaseOfPageNullable,
  CatalogCategoryType,
  CatalogExtendedRelation,
  CatalogMenuType,
  CategoryFull,
  CategoryRelation,
  CategoryShort,
  CollectionType,
  FullProductType,
  GlossaryCardType,
  PostFull,
  PostImage,
  PostShort,
  ProductPreview,
  ProductRelation,
  ProductsCategoryType,
  ProductType,
  SeoBlogPageType,
} from '@/typings/model';
import {StringFieldType} from '@/typings/common';
import {Filter} from '@/typings/filter/types';

export type OrderFormPayload = {
  productId: number;
  option: string;
  name: string;
  phone: string;
};

export type CheaperFormPayload = {
  productId: number;
  option: string;
  name: string;
  phone: string;
  price: string;
  shop: string;
};

export type PostSearchShort = {
  readonly id: number;
  readonly language: StringFieldType;
  readonly urlAlias: string;
  readonly title: string;
  readonly date: string;
  readonly excerpt: StringFieldType;
  readonly coverImage: PostImage;
  readonly categories: Array<Pick<CategoryShort, 'name' | 'urlAlias'>>;
};

export type FeedbackFormPayload = {
  name: string;
  phone: string;
  message: string;
};

export type CallFormPayload = {
  name: string;
  phone: string;
};

const apiService = new ApiService({
  baseUrl: isServer() ? process.env.NEXT_PUBLIC_SSR_API_URL : process.env.NEXT_PUBLIC_CSR_API_URL,
});

const request = apiService.getRequest();

export function getCatalogMenu(): Promise<ResponseBody<Array<CatalogMenuType>>> {
  return request.get({
    path: '/categories/catalog',
  });
}

export function getProductListByGroupList(
  group: string
): Promise<ResponseBody<Array<ProductPreview>>> {
  return request.get({
    path: `/products/by-group/${group}`,
  });
}

export function getProductByAlias(
  alias: string
): Promise<ResponseBody<FullProductType>> {
  return request.get({
    path: `/products/${alias}`,
  });
}

export function getProductPostListById(
  id: number
): Promise<ResponseBody<Array<PostShort>>> {

  return request.get({
    path: `/products/${id}/posts`,
  });
}

export function getProductRelationListById(
  id: number
): Promise<ResponseBody<Array<ProductRelation>>> {
  return request.get({
    path: `/products/${id}/relations`,
  });
}

export function getBlogCategoryList(): Promise<ResponseBody<Array<CategoryShort>>> {
  return request.get({
    path: '/tager/blog/categories',
  });
}

export function getCategoryByAlias(
  alias: string
): Promise<ResponseBody<CategoryFull>> {

  return request.get({
    path: `/tager/blog/categories/${alias}`,
  });
}

export function getPostByAlias(alias: string): Promise<ResponseBody<PostFull>> {
  return request.get({
    path: `/tager/blog/posts/view/${alias}`,
  });
}

export function getPostList(): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: '/tager/blog/posts',
  });
}

export function getSeoParams(): Promise<ResponseBody<SeoBlogPageType>> {
  return request.get({
    path: '/tager/blog/seo-params',
  });
}

export function getGlossary(): Promise<ResponseBody<Array<GlossaryCardType>>> {
  return request.get({
    path: '/vocabulary',
  });
}

export function sendCallForm(payload: CallFormPayload) {
  return request.post({
    path: '/leads/call',
    body: payload,
  });
}

export function sendFeedbackForm(payload: FeedbackFormPayload) {
  return request.post({
    path: '/leads/contact',
    body: payload,
  });
}

export function getSearchPostList(params: {
  query: string;
  count?: number;
}): Promise<ResponseBody<Array<PostSearchShort>>> {
  return request.get({
    path: '/search/blog',
    params: params,
  });
}

export function getSearchProductList(params: {
  query: string;
  count?: number;
}): Promise<ResponseBody<Array<ProductType>>> {
  return request.get({
    path: '/search/products',
    params: params,
  });
}

export function getSearchCategoryList(params: {
  query: string;
  count?: number;
}): Promise<ResponseBody<Array<ProductsCategoryType>>> {
  return request.get({
    path: '/search/categories',
    params: params,
  });
}

export function sendOrderForm(payload: OrderFormPayload) {
  return request.post({
    path: '/leads/product-order',
    body: payload,
  });
}

export function sendCheaperForm(payload: CheaperFormPayload) {
  return request.post({
    path: '/leads/product-cheaper',
    body: payload,
  });
}

export function getBrandsByAlias(
  alias: string
): Promise<ResponseBody<BaseOfPageNullable>> {
  return request.get({
    path: `/brands/${alias}`,
  });
}

export function getBrandRelatedProducts(
  id: number,
  searchParams: URLSearchParams
): Promise<ResponseBody<ProductType[]>> {
  return request.get({
    path: `/brands/${id}/products`,
    params: searchParams,
  });
}

export function getCollectionByAlias(
  alias: string
): Promise<ResponseBody<CollectionType>> {
  return request.get({
    path: `/collections/${alias}`,
  });
}

export function getCollectionProductsById(
  id: number,
  searchParams: URLSearchParams
): Promise<ResponseBody<ProductType[]>> {
  return request.get({
    path: `/collections/${id}/products`,
    params: searchParams,
  });
}

/**BlogNavigation **/
export function getCatalogCategoryByAlias(params: {
  path: string;
}): Promise<ResponseBody<CatalogCategoryType>> {
  return request.get({
    path: `/categories`,
    params: params,
  });
}

export function getCatalogCategoryRelationsById(
  id: number
): Promise<ResponseBody<CategoryRelation[]>> {
  return request.get({
    path: `/categories/${id}/relations`,
  });
}

export function getCatalogCategoryRelationsExtendedById(
  id: number
): Promise<ResponseBody<CatalogExtendedRelation[]>> {
  return request.get({
    path: `/categories/${id}/relations-extended`,
  });
}

export function getCatalogCategoryProductsById(
  id: number,
  params?: QueryParams
): Promise<ResponseBody<ProductType[]>> {

  return request.get({
    path: `/categories/${id}/products`,
    params: params,
  });
}

export function getProductFiltersByProductId(
  productId: number
): Promise<ResponseBody<Filter[]>> {
  return request.get({
    path: `/categories/${productId}/filters`,
  });
}
