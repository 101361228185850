import { QueryParams } from '@tager/web-core/src/typings/common';

import { SORT_TYPE } from '@/constants/sort';

export const SEARCH_PARAMS: { [key: string]: string } = {
  pageLimit: 'pageLimit',
  pageOffset: 'pageOffset',
  sort: 'sort',
  page: 'page',
};

export const DEFAULT_SEARCH_PARAMS = {
  PAGE_LIMIT: 'pageLimit',
  PAGE_OFF_SET: 'pageOffset',
  SORT: 'sort',
  PAGE: 'page',
} as const;

export const DEFAULT_SEARCH_PARAMS_VALUES: QueryParams = {
  [DEFAULT_SEARCH_PARAMS.SORT]: SORT_TYPE.Popular,
  [DEFAULT_SEARCH_PARAMS.PAGE_LIMIT]: 50,
  [DEFAULT_SEARCH_PARAMS.PAGE_OFF_SET]: 0,
  [DEFAULT_SEARCH_PARAMS.PAGE]: 1,
};
