import { Filter, FilterTypes } from '@/typings/filter/types';
import { Values } from '@/store/reducers/pages/filters/CatalogProductFilters/filters';

export const toValues = (filters: Filter[]) =>
  filters.reduce<Values>((values, filter) => {
    switch (filter.type) {
      case FilterTypes.MultiSelect: {
        values[filter.id] = {
          type: filter.type,
          data: filter.options
            .filter((option) => option.checked)
            .map((option) => option.id),
        };
        return values;
      }
      case FilterTypes.Range: {
        values[filter.id] = {
          type: filter.type,
          data: [filter.valueFrom ?? 0, filter.valueTo ?? 0],
        };
        return values;
      }
      case FilterTypes.TrueFalse: {
        values[filter.id] = { type: filter.type, data: null };
        return values;
      }
      case FilterTypes.Radio: {
        values[filter.id] = { type: filter.type, data: null };
        return values;
      }
      default:
        return values;
    }
  }, {});
